//  // Color themes Map
// $themes: (
//   default: #666,
//   banana: #f1c40f,
//   cherry: #c0392b,
//   blueberry: #8e44ad,
//   leaf: #27ae60,
//   nightsky: #2980b9
// );

// // @param $name: name of the theme (HTML class)
// // @param $color: color of the theme
// @mixin theme($name, $color) {
//   .#{$name} {
//     .topbar-left , .navbar.navbar-default{
//       background : $color !important ;
//     }

//     a.logo{
//       color: darken($color, 30%) !important;
//     }
//   }

// }

// @each $key, $value in $themes {
//   @include theme($key, $value);
// }

// backgroundColor: #222,
// textColor: #ddd,
// buttonTextColor: #aaa,
// buttonTextTransform: uppercase,
// buttonTextHoverColor: #ddd,
// buttonColor: #333,
// buttonBorder: 1px solid #aaa

//#3c4752 , //424951

$successColor: #00c851;
$warningColor: #ffa000;
$dangerColor : #ff3547;

$themes: (
  dark: (backgroundPrimary: #253138,
    backgroundSecondary: #2f3e47,
    lines: 1px solid #98a6ad,
    textDark: #f3f3f3,
    textMuted: #98a6ad,
    headings: rgba(255, 255, 255, 0.9),
    placeholder:#98a6ad,
    formBorder: 1px solid rgba(255, 255, 255, 0.15),
    boxShadow: 2px solid #fff,
    ocrTextColor: white,
    actionBorderColor:#475E6C,
    actionBgColor:#354650),
  light: (backgroundPrimary: #beb8b8,
    backgroundSecondary: #F6EFE8,
    lines: 1px solid rgb(26, 24, 24),
    textDark: #0a0a0a,
    textMuted: rgb(26, 24, 24),
    headings: rgba(0, 0, 0, 0.8),
    placeholder:#98a6ad,
    formBorder: 1px solid rgba(0, 0, 0, 0.15),
    boxShadow: 2px solid #fff,
    ocrTextColor: black,
    actionBorderColor:#d4d4d4,
    actionBgColor:#ececec),
  golden: (backgroundPrimary: #4f575f,
    backgroundSecondary: #2074c9,
    textDark: #f3f3f3,
    textMuted: #98a6ad,
    headings: #ffce61,
    formBorder: none,
    boxShadow: 2px solid #fff,
    ocrTextColor: black,
    actionBorderColor:#475E6C,
    actionBgColor:#354650)
);

@mixin themify($themes: $themes) {

  @each $theme,
  $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;

      @each $key,
      $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge($theme-map,
          ($key: $value )) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

.topbar-left,
.navbar.navbar-default,
.content-page,
.info-primary-card,
.text-transparent-button,
.container-chip {
  @include themify($themes) {
    background-color: themed('backgroundPrimary') !important;
  }
}
.card-box-secondary{
  @include themify($themes) {
    background-color: themed('backgroundPrimary') !important;
  }
}
.aml-details-card {
  .fa {
    @include themify($themes) {
      background-color: themed('backgroundPrimary') !important;
    }
  }
}

.card-box,
.payment-card,
.left-sidebar-menu,
.side-menu,
#sidebar-menu>ul>li>a,
.form-control,
#leftPaneSettings,
.notifi,
#leftPane,
.modal .modal-dialog .modal-content {
  @include themify($themes) {
    background-color: themed('backgroundSecondary') !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.app-search button,
.form-control,
.logo,
.subTextVisible,
.button-menu-mobile,
#sidebar-menu>ul>li>a.active,
.table,
.notification-box ul li a {
  @include themify($themes) {
    color: themed('headings') !important;
  }
}

.sectionHr,
.boxBorder {
  @include themify($themes) {
    border-color: themed('headings') !important;
  }
}

.mainlist {
  @include themify($themes) {
    color: themed('ocrTextColor') !important;
  }

  font-size: 15px;
}


::placeholder {
  @include themify($themes) {
    color: themed('placeholder') !important;
  }
}

.ocrtext {
  @include themify($themes) {
    color: themed('ocrTextColor') !important;
  }
}

#sidebar-menu>ul>li>a.active {
  @include themify($themes) {
    border-left: 3px solid themed('headings') !important;
    font-weight: bolder;
  }
}

// .card-box,
.form-control,
.table>thead>tr>th,
.table-bordered>thead>tr>th,
.table-bordered>tbody>tr>th,
.table-bordered>tfoot>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>tbody>tr>td,
.table-bordered>tfoot>tr>td,
.table>tbody>tr>td,
.table>tbody>tr>th {
  @include themify($themes) {
    border: themed('formBorder') !important;
  }
}

div.user-box,
div.topbar-left,
.aml-card-detail-container,
.aml-form-container {
  @include themify($themes) {
    border-bottom: themed('formBorder') !important;
  }
}

.horizontal-line {
  @include themify($themes) {
    border-top: themed('lines') !important;
  }
}

.border-line-secondary {
  @include themify($themes) {
    border: 1px solid themed('backgroundSecondary') !important;
  }
}

.total-stats-box{
  @include themify($themes) {
    border: themed('lines') !important;
  }
}

.card-with-top-bottom-border{
  @include themify($themes) {
    border-top: themed('lines') !important;
    border-bottom: themed('lines') !important;
  }
}

.themed-border-line {
  @include themify($themes) {
    border: 1px solid themed('actionBorderColor') !important;
  }
}

.text-muted,
.ng-otp-input-wrapper .otp-input {
  @include themify($themes) {
    color: themed('textMuted') !important;
  }
}

.vertical-line {
  @include themify($themes) {
    border-left: 1px solid themed('backgroundPrimary') !important;
  }
}

.primary-chips {
  @include themify($themes) {
    border: themed('lines') !important;
    color: themed('textDetails') !important;
    background-color: themed('backgroundSecondary') !important;
  }
}

.horizontal-line {
  @include themify($themes) {
    border-top: 1px solid themed('backgroundPrimary') !important;
  }
}

.horizontal-line-secondary {
  @include themify($themes) {
    border-top: themed('lines') !important;
  }
}

.aml-secondary-border{
  @include themify($themes) {
    border: themed('lines') !important;
  }
}

.secondary-border {
  @include themify($themes) {
    border: 2px solid themed('backgroundPrimary') !important;
  }
}

.text-dark {
  @include themify($themes) {
    color: themed('textDark') !important;
  }
}

.kyc-border-right {
  @include themify($themes) {
    border-right: 1px solid themed('backgroundPrimary') !important;
  }
}

.report-profile-image, .article-profile-image {
  @include themify($themes) {
    background-color: themed('backgroundPrimary');
  }
}

// Tabs

.report-scroll-tabs {
  .tab-items {
    @include themify($themes) {
      border: 2px solid themed('textMuted');
      color: themed('textDark') !important;
    }

    &.active {
      @include themify($themes) {
        background-color: themed('backgroundPrimary');
      }
    }
  }
}

// Latest Table

.latest-table.table {
  @include themify($themes) {
    background-color: themed('backgroundSecondary');
  }

  thead tr th {
    @include themify($themes) {
      background-color: themed('backgroundPrimary') !important;
      color: themed('textMuted') !important;
    }
  }

  tbody tr td {
    @include themify($themes) {
      border: 0.5px solid themed('backgroundPrimary') !important;
      color: themed('textDark') !important;
    }
  }
}

// Themed Card
.themed-primary-background {
  @include themify($themes) {
    background-color: themed('backgroundPrimary') !important;
  }
}

.themed-secondary-background {
  @include themify($themes) {
    background-color: themed('backgroundSecondary') !important;
  }
}

.ng-select {
  .ng-select-container {
      @include themify($themes) {
          border: themed('lines') !important;
          color: themed('textDark') !important;
      }
  }
}
.ng-dropdown-panel {
  .ng-dropdown-panel-items {
      @include themify($themes) {
          background-color: themed('backgroundSecondary') !important;
          border: themed('lines') !important;
          border-radius: 12px;
      }
      .ng-option {
          @include themify($themes) {
              background-color: transparent !important;
          }
          .optionValue {
              @include themify($themes) {
                  background-color: transparent !important;
                  color: themed('textDark') !important;
                  border-bottom: themed('lines');
              }
          }
          .optionValue:hover {
              @include themify($themes) {
                  background-color: themed('backgroundPrimary') !important;
                  color: themed('textDark') !important;
                  border-bottom: themed('lines');
              }
          }
      }
  }
}

// Half-circle Indicator theme
.half-arc::after {
  @include themify($themes) {
    background-color: themed('backgroundSecondary') !important;
  }
}

// Radio Buttons

.form-radio-buttons {
  label {
    @include themify($themes) {
      color: themed('textMuted') !important;
    }
  }

  input[type="radio"] {
    +.radio-label {
      &:before {
        @include themify($themes) {
          border: 2px solid themed('textMuted');
        }
      }
    }

    &:checked {
      +.radio-label {
        &:before {
          @include themify($themes) {
            box-shadow: inset 0 0 0 4px themed('backgroundSecondary');
          }
        }
      }
    }
  }
}

// Badges

.secondary-badge {

  &.green,
  &.changes-found,
  &.border-transparent-bg {
    @include themify($themes) {
      border: 2px solid themed('textMuted') !important;
    }
  }

  &.dark {
    @include themify($themes) {
      background-color: themed('backgroundPrimary') !important;
      color: themed('textMuted') !important;
    }
  }
}

.customCheckbox label {
  @include themify($themes) {
    color: themed('textMuted') !important;
  }

  &::before {
    @include themify($themes) {
      border: 2px solid themed('textMuted');
    }
  }
}

.table-icons {
  @include themify($themes) {
    border: 2px solid themed('textMuted') !important;
  }
}

// Pagination

.ngx-pagination {
  .current {
    @include themify($themes) {
      background-color: themed('backgroundPrimary') !important;
      color: themed('textDark') !important;
    }
  }

  li>a,
  li.disabled {
    @include themify($themes) {
      color: themed('textMuted');
      border: 2px solid themed('textMuted');
    }

    &:hover {
      @include themify($themes) {
        background-color: themed('backgroundPrimary') !important;
      }
    }
  }

}

//Generic Ones

.btn-success.btn-status,
.btn-success.btn-action {
  color: white;
  background: $successColor !important; //original green;
  // background: #2bbbad !important ; //classy green
  border: 1px solid $successColor ;
}

.btn-warning.btn-status,
.btn-warning.btn-action {
  color: white;
  background: $warningColor !important; //Amber color
  border: 1px solid $warningColor ;
}

.btn-danger.btn-status,
.btn-danger.btn-action {
  color: white;
  background: $dangerColor !important;
  border: 1px solid $dangerColor ;
}

.btn-kyc {
  background: #F94F06;
  border: 1px solid #F94F06;
  color: white !important;
}

.btn {
  overflow: hidden;
  text-overflow: ellipsis;
  // box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12) !important;
}

.btn-action {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12) !important;
  opacity: 0.9;
  transition: all ease 0.5s;

  &:hover {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15) !important;
    outline: 0;
    opacity: 1;
  }
}

.text-success {
  color: $successColor !important;
}

.text-danger {
  color: $dangerColor !important;
}

.text-selected-entity {
  color: #0075FF !important;
}

.text-selected-entity-title {
  color: #177CDA !important;
}

.text-entity {
  color: #fff
}

.text-warning {
  color: $warningColor !important;
}

.bg-success,
.badge-success {
  background: $successColor !important;
}

.bg-danger,
.badge-danger {
  background: $dangerColor !important;
}

.bg-warning,
.badge-warning {
  background: $warningColor !important;
}

.bg-primary,
.badge-primary {
  background: $primary !important;
}


.ngx-charts {
  @include themify($themes) {
    text {
      fill: themed('ocrTextColor') !important;
    }
  }
}

//left right 2 px border
.widget-card-border-lr {
  @include themify($themes) {
    border-left: 2px solid themed('backgroundSecondary');
    border-right: 2px solid themed('backgroundSecondary');
  }
}

.cardbox-title-border-btm {
  @include themify($themes) {
    border-bottom: 2px solid themed('backgroundPrimary');
  }
}

.border-left-card {
  @include themify($themes) {
    border-left: 2px solid themed('backgroundPrimary');
  }
}

.dots-9{
  @include themify(($themes)) {
    border: 2px solid themed('actionBorderColor');
  }  
}

.swtich-plt{
  &:hover{
      @include themify(($themes)) {
          background-color: themed('backgroundPrimary');
      }  
  }
}

.round-action-wrap {
  @include themify($themes) {
    display: flex;
    align-items: center;
    justify-content: center;
    background: themed('actionBgColor');
    border: 1px solid themed('actionBorderColor');
    border-radius: 12px;
    height: 36px;
    width: 36px;
  }
}

.hr-partition {
  @include themify($themes) {
    border-top: 1px solid themed('backgroundPrimary');
    margin: 0px
  }
}

// Scrollbar

::-webkit-scrollbar-track {
  @include themify($themes) {
      background-color: themed('backgroundPrimary') !important;
  }
}

::-webkit-scrollbar-thumb {
  @include themify($themes) {
      background-color: #98A6AD !important;
  }
}